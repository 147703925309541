import apiService from 'services/apiService'
import { LoginData } from 'services/apiService/types'

class AuthService {
  apiService: any = null

  constructor() {
    this.apiService = apiService
  }

  login = async (loginData: LoginData) => {
    const res = await this.apiService.auth(loginData)
    return res.data
  }

  me = async (): Promise<User> => {
    try {
      const res = await this.apiService.read('me', null)
      return res.data
    } catch (err) {
      console.log(err)
      return null
    }
  }
}


export default AuthService