import Layout from './Layout'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


type BaseLayoutProps = {}


const BaseLayout: React.FC<BaseLayoutProps> = (props) => (
  <>
    <ToastContainer
      position="top-center"
      theme="colored"
      autoClose={30000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
    />
    <Layout>
      {props.children}
    </Layout>
    <iframe id="printer" name="printer" width="1px" height="1px" title="printer" />
  </>
)

BaseLayout.whyDidYouRender = false

export default BaseLayout