import { useStoreState } from 'store'

type UserViewProps = {
}


const UserView: React.FC<UserViewProps> = () => {
  const user = useStoreState(store => store.user)

  return (
    <span className="border border-secondary rounded mr-3 px-2 py-1">
      <h5 className="m-0 text-white">
        {user ? user.username : '---'}
      </h5>
    </span>
  )
}


export default UserView