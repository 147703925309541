import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import add from 'date-fns/add'
import sub from 'date-fns/sub'

import ensureDate from '../ensureDate'

const getDiasFestivos = (festivos: (string | Date)[], desde: string | Date, hasta: string | Date): Date[] => {
  /**
   * A esta función se le pasa un array de dias festivos y las fechas desde y hasta
   * y devuelve un array con los festivos entre esas dos fechas desde/hasta.
   * 
   * TODO: Si discardWeekend es true, no tiene en cuenta los festivos en fin de semana (Para facturación LABORABLES)
   */

  // let result = 0
  const _desde = endOfDay(sub(ensureDate(desde), { days: 1 }))
  const _hasta = startOfDay(add(ensureDate(hasta), { days: 1 }))

  const diasFestivos = festivos.map(dia => ensureDate(dia)).filter(dia => (isAfter(dia, _desde) && isBefore(dia, _hasta)))
  /*
  result = diasFestivos.length
  festivos.forEach(dia => {
    const __dia = ensureDate(dia)

    if (!discardWeekend && isBetweenDates(__dia, _desde, _hasta)) {
      result = result + 1
    }

    if (!isWeekend(__dia) && isBetweenDates(__dia, _desde, _hasta)) {
      result = result + 1
    }
  })
  
  */

  return diasFestivos
}

export default getDiasFestivos