import getLogger from 'utils/logger'
import appVersion from './appVersion.json'

const logger = getLogger('settings')

const API_SERVER_PORT = 8801
const PROTOCOL = window.location.hostname === 'localhost' ? 'http://' : 'https://'
const backendServer = window.location.hostname === 'localhost' ? 'localhost:8000' : `${window.location.hostname}:${API_SERVER_PORT}`

if (process.env.NODE_ENV !== 'production') {
  logger.info(`>>> ${process.env.NODE_ENV} - _ENTORNO DE DESARROLLO_ [BACKEND: ${backendServer}] [${window.location.host}] <<<`)
}

// Estas URLs son las relacionadas con el backend de django
export const remote = `${PROTOCOL}${backendServer}`
export const apiAuthURL = `${PROTOCOL}${backendServer}/api-token-auth/`
export const apiURL = `${PROTOCOL}${backendServer}/api`
export const authEndPoint = 'api-token-auth/'

export const getAppVersion = (): string => {
  const versionDate = appVersion.date.split('-').join('')
  const version = `v${versionDate}-${appVersion.iteration}`
  return version
}