/*
https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/
*/

interface WithShowProps {
  show: boolean
}

const withShow = <T extends object>(WrappedComponent: React.ComponentType<T>) => (props: T & WithShowProps) => {
  const { show, ...restProps } = props

  // const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  // const ComponentWithShow = (props: Omit<T, keyof WithShowProps>) => {
  //   return <WrappedComponent {...(props as T)} />
  // }

  // ComponentWithShow.displayName = `withShow(${displayName})`

  return show ? <WrappedComponent {...restProps as T} /> : null
}


export default withShow