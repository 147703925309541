// import secureStorage from '../SecureStorage'
import getLogger from 'utils/logger'

const logger = getLogger('appConfigRepository')
interface AppConfigRepository {
  getAppConfig: () => AppConfigSettings
  getOrCreateAppConfig: () => AppConfigSettings
  setAppConfig: (appConfig: AppConfigSettings) => void
  clearAppConfig: () => void
}

interface AppConfigSettings {
  warehouse: number
}


const defaultAppConfig: AppConfigSettings = {
  warehouse: 0
}


const getOrCreateAppConfig = (): AppConfigSettings => {
  let appConfig = null
  console.log('>> getOrCreateAppConfig')
  try {
    appConfig = getAppConfig()
    console.log('getOrCreateAppConfig obtenida configuracion:', appConfig.warehouse)
  } catch (e) {
    console.error(e)
  }

  if (!Boolean(appConfig)) {
    console.log('getOrCreateAppConfig: No se recuperó ninguna configuración.')
    setAppConfig(defaultAppConfig)
    console.log('getOrCreateAppConfig se establece config por defecto', defaultAppConfig)
    return defaultAppConfig
  }

  return appConfig
}

const getAppConfig = (): AppConfigSettings | null => {
  // const appConfig = secureStorage.getItem('appConfig')
  const appConfig = localStorage.getItem('appConfig')
  console.log('getAppConfig', appConfig)
  return appConfig ? JSON.parse(appConfig) : null
}

const setAppConfig = (appConfig: AppConfigSettings): void => {
  console.log('setAppConfig', appConfig)
  //secureStorage.setItem('appConfig', appConfig)
  localStorage.setItem('appConfig', JSON.stringify(appConfig))
}

const clearAppConfig = (): void => {
  console.log('clearAppConfig')
  // secureStorage.clear('appConfig')
  localStorage.removeItem('appConfig')
}


const appConfigRepository: AppConfigRepository = {
  getAppConfig,
  getOrCreateAppConfig,
  setAppConfig,
  clearAppConfig,
}

export default appConfigRepository