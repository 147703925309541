import format from 'date-fns/format'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from "date-fns/locale/es"
import styles from './styles.module.sass'

registerLocale("es", es)

const isHoliday = (date: Date, holidays: string[]) => {

  const dateString = format(date, 'yyyy-MM-dd')
  return holidays.includes(dateString) || (date.getDay() === 0)
}


type DateRangeSelectorProps = {
  name: string
  label: string
  startDate: Date
  endDate: Date
  minDate: any
  maxDate: any
  onChange: (values: [Date, Date]) => void
  holidays: any
  isClearable?: boolean
  placeHolder: string
  classes: string
  inputClasses: string
  disabled?: boolean
}


const DateRangeSelector: React.FC<DateRangeSelectorProps> = (props) => {
  const {
    name, label, startDate, endDate, holidays,
    onChange, disabled, isClearable, classes, inputClasses
  } = props


  const dayClass = (date: Date): string | undefined => {
    return isHoliday(date, holidays || []) ? styles.holiday : undefined
  }

  const handleChange = (values: [Date, Date]) => {
    // console.log(values)
    onChange(values)
  }


  const display = `${startDate ? format(startDate, 'dd/MM/yy') : 'Seleccionar fecha inicio'} - ${endDate ? format(endDate, 'dd/MM/yy') : 'Seleccionar fecha fin'}`


  return (
    <div className={`form-group h-25 small font-weight-bold ${classes || ''}`}>
      <label htmlFor={`datePicker_${name}`} className='mb-0'>{label}</label>
      <DatePicker
        locale="es"
        dateFormat="P"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        withPortal
        dayClassName={dayClass}
        className="custom-form-control form-control"
        customInput={
          <div className='col'>
            {display}
          </div>
        }
      />
    </div >
  )

}


export default DateRangeSelector