import { useState, useEffect } from 'react'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import formatDateTimeAPI from 'utils/datetime/formatDateTimeAPI'

import facturasService from 'services/DataServices/facturasService'
import warehousesService from 'services/DataServices/warehousesService'


interface CondicionesFacturasActualizables {
  desde: Date
  hasta: Date
}


const useFacturasActualizables = (condiciones: CondicionesFacturasActualizables) => {
  const [facturas, setFacturas] = useState<FacturaAPI[]>([])
  const [almacenes, setAlmacenes] = useState<AlmacenAPI[]>([])

  useEffect(() => {
    const getData = async () => {
      const data = await warehousesService.list({ ordering: 'id', fields: 'id,holidays' })

      setAlmacenes(data)
    }

    getData()
  }, [])

  useEffect(() => {
    const getFacturas = async () => {
      const filtro: any = {
        fields: 'id,ref,almacen,venta,inicioFactura,finFactura,sabadosHabiles,festivos',
        ordering: 'almacen,ano,numero',
      }

      filtro.fechaEmision__gte = formatDateTimeAPI(startOfDay(condiciones.desde))
      filtro.fechaEmision__lte = formatDateTimeAPI(endOfDay(condiciones.hasta))

      // console.log(filtro)

      const data = await facturasService.list(filtro)

      setFacturas(data)
    }

    getFacturas()
  }, [condiciones])

  return [facturas, almacenes]
}


export default useFacturasActualizables