import { useStoreActions } from 'store'

import styles from './styles.module.sass'
import logo from 'assets/img/logo.png'
import useVerifyJWT from 'hooks/useVerifyJWT'
import WarehouseSelector from './WarehouseSelector'
import UserView from './UserView'
import { AppState } from 'store/types'


type TopBarProps = {
  onCollapse: () => void
}

const TopBar: React.FC<TopBarProps> = (props) => {
  const { onCollapse } = props
  const logout = useStoreActions<AppState>(actions => actions.logout)

  const handleLogout = () => { logout() }

  useVerifyJWT(5000, logout) // Cada 5 segs se verifica si el token está caducado

  return (
    <nav className={`navbar navbar-expand d-flex align-items-center ${styles.topbar}`}>
      <div className={styles.logo}>
        <span className={styles.collapse} onClick={onCollapse}><i className="fas fa-bars" /></span>
        <img src={logo} alt="Seralven Logo" />
      </div>
      <div className={styles.toolbar}>
        <UserView />
        <WarehouseSelector />
        <span className={styles.logoutButton} onClick={handleLogout}><i className="fad fa-sign-out" /></span>
      </div>
    </nav>
  )
}

TopBar.whyDidYouRender = false

export default TopBar