import { useEffect } from 'react'
import { useStoreState, useStoreActions } from 'store'
import { useQuery } from 'react-query'

import appConfigRepository from 'infrastructure/repository/appConfigRepository'
import getLogger from 'utils/logger'
import warehousesService from 'services/DataServices/warehousesService'
import { AppState } from 'store/types'

import styles from './styles.module.sass'

const logger = getLogger('WarehouseSelector')

type WarehouseSelectorProps = {}

const WarehouseSelector: React.FC<WarehouseSelectorProps> = () => {
  const { warehouse, warehouseLocked } = useStoreState(store => store)
  const { setWarehouse, setFestivos, setWarehouses } = useStoreActions<AppState>(actions => actions)

  // const { almacenAux, setAlmacenAux } = useState(null)

  const fetchAlmacenes = async () => {
    const almacenes = await warehousesService.list()
    setWarehouses(almacenes)
    return almacenes
  }

  const warehousesQuery = useQuery('almacenes', fetchAlmacenes, { enabled: false })
  useEffect(() => { warehousesQuery.refetch() }, [])


  useEffect(() => {
    // Si no hay warehouse, obtener de SesionStorage
    // Esto se ejecuta la 
    logger.info('Ha cambiado warehouses')

    const appConfig = appConfigRepository.getOrCreateAppConfig()
    logger.info(`Se ha obtenido la siguiente configuración ${JSON.stringify(appConfig)}`)
    logger.info('appConfig', appConfig)

    if (warehousesQuery.isSuccess) {
      logger.info('1 - appConfig', appConfig)
      const warehouseAllowed = warehousesQuery.data.map(w => w.id).includes(appConfig.warehouse)
      logger.info('2 - warehouseAllowed', warehouseAllowed)

      if (warehouseAllowed) {
        // Si el warehouse almacenado está autorizado, úsalo
        logger.info(`El usuario tiene el almacén ${appConfig.warehouse} permitido.Estableciendo el almacen.`)
        setWarehouse(appConfig.warehouse)
        const almacen = warehousesQuery.data.find((w: Almacen) => (w.id == appConfig.warehouse))
        setFestivos(almacen ? almacen.holidays : [])
        //return
      } else {
        // Si el warehouse almacenado no está autorizado, usa el primero que esté autorizado
        if (warehousesQuery.data && warehousesQuery.data.length) {
          logger.info(`El almacén almacenado(${appConfig.warehouse}) no está autorizado para el usuario`)
          setWarehouse(warehousesQuery.data[0].id)
          setFestivos(warehousesQuery.data[0].festivos)
          logger.info(`Establecido el primer almacén disponible: ${warehousesQuery.data[0].id} - ${warehousesQuery.data[0].name}`)
          appConfig.warehouse = warehousesQuery.data[0].id
          logger.info(appConfig, JSON.stringify(appConfig))
          appConfigRepository.setAppConfig(appConfig)
          logger.info(`Se ha guarda la siguiente configuración ${JSON.stringify(appConfig)}`)
        } else {
          logger.info(`No hay almacenes que seleccionar`)
        }
      }
    }

    return (() => {
      // const newAppConfig = appConfigRepository.getOrCreateAppConfig()
      // newAppConfig.warehouse = warehouse
      // appConfigRepository.setAppConfig(newAppConfig)
      // logger.info(`Finalización de useEffect: Se ha guarda la siguiente configuración ${JSON.stringify(appConfig)}`)
    })
  }, [warehousesQuery.data])


  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setWarehouse(parseInt(e.target.value))
    const almacen = warehousesQuery.data.find(a => (a.id == e.target.value))
    setFestivos(almacen ? almacen.holidays : [])
    const appConfig = appConfigRepository.getOrCreateAppConfig()
    appConfig.warehouse = parseInt(e.target.value)
    logger.info(`Cambiado el almacén... Se ha guarda la siguiente configuración ${JSON.stringify(appConfig)}`)
    appConfigRepository.setAppConfig(appConfig)
  }

  const isDisabled = warehouseLocked || Boolean(warehousesQuery && (warehousesQuery.isLoading || (warehousesQuery.data?.length <= 1)))


  return (
    <select
      value={warehouse || ''}
      onChange={handleChange}
      disabled={isDisabled}
      className={`${styles.warehouseSelector} ${warehouseLocked && styles.warehouseSelectorLocked} form-control col-md-2 col-sm-1 mr-2`}
    >
      {
        warehousesQuery.isSuccess && warehousesQuery.data.map((item: any) => (<option key={item.id} value={item.id}>{item.name}</option>))
      }
    </select>
  )
}

export default WarehouseSelector