import Normalize from './Normalize'


abstract class DataClass<T, P> implements Normalize<T, P> {
  data: P
  isNew: boolean

  abstract normalize(data: T): P
}


export default DataClass