import parse from 'date-fns/parse'


const ensureDate = (date: string | Date, format: string = 'dd/MM/yyyy'): Date | null => {
  if (date instanceof Date) return date
  if (typeof date === 'string') return parse(date, format, new Date())

  return null
}


export default ensureDate