import ModelAPIService from 'services/apiService/ModelAPIService'
import apiService from 'services/apiService'


class AlmacenesService extends ModelAPIService<AlmacenAPI> {
  AllWarehousesListURL: string = 'allWarehouses'

  constructor() {
    super('warehouses')
  }

  allWarehousesList = async (): Promise<AlmacenAPI[]> => {
    const res = await apiService.list(this.AllWarehousesListURL)
    return res.data as AlmacenAPI[]
  }
}

const warehousesService = new AlmacenesService()


export default warehousesService