const ESTADOS_FACTURA = {
  CREADA: 0,
  EMITIDA: 100,
  PENDIENTE_COBRO: 500,
  COBRADA: 900,
  ABONADA: 910,
  ANULADA: 999,

  // * ******************
  PENDIENTE_ABONAR: 1000,
  ABONO: 1010,
}

export default ESTADOS_FACTURA