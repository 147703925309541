import { useEffect, Suspense, StrictMode, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import Routes from './routes'
import { useStoreActions, useStoreState } from 'easy-peasy'

import AwaitingOverlay from 'BaseLayout/AwaitingOverlay'
import styles from './styles.module.sass'
import { AppState } from 'store/types'
import { useInterval } from 'react-use'

// import consolere from 'console-remote-client'

const queryClient = new QueryClient()

// const REMOTE_CONSOLE_SERVER = 'https://seralven.turipass.es:9443'

// const ACTIVATE_REMOTE_LOGGER = process.env.NODE_ENV === 'production'

// https://github.com/kurdin/console-remote-server


type AppProps = {}

const App: React.FC<AppProps> = () => {
  const refreshUserData = useStoreActions<AppState>(actions => actions.refreshUserData)
  const refreshTraspasosPendientesRecepcion = useStoreActions<AppState>(actions => actions.refreshTraspasosPendientesRecepcion)
  const warehouse = useStoreState<AppState>(state => state.warehouse)
  // const user = useStoreState<AppState>(state => state.user)
  // const [REMOTE_CONSOLE_KEY, setRemoteConsoleKey] = useState<string>('seralven-default-console')

  /*
  const connectRemoteConsole = (userName: string) => {
    setRemoteConsoleKey(userName)

    if (ACTIVATE_REMOTE_LOGGER) {
      consolere.connect({
        // server: REMOTE_CONSOLE_SERVER, // optional, default: https://console.re
        channel: `seralven-${userName}`, // required
        redirectDefaultConsoleToRemote: true, // optional, default: false
        // disableDefaultConsoleOutput: true, // optional, default: false
      })
    }
  }
  */

  useEffect(() => {
    console.log('Refrescando datos de usuario')
    refreshUserData()
  }, [refreshUserData])

  /*
  useEffect(() => {
    if (user && (user.username !== REMOTE_CONSOLE_KEY)) {
      console.log('USER', user.username)
      connectRemoteConsole(user.username)
    }
  }, [user])
  */

  useEffect(() => {
    console.log('Refrescando traspasos pendientes')
    refreshTraspasosPendientesRecepcion(warehouse)
  }, [warehouse, refreshTraspasosPendientesRecepcion])

  useInterval(
    () => {
      console.log('refreshTraspasosPendienteRecepcion')
      refreshTraspasosPendientesRecepcion(warehouse)
    }, 30000
  )

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <div className={styles.app}>
            <Suspense fallback={<AwaitingOverlay awaiting />}>
              <Routes />
            </Suspense>
          </div>
        </BrowserRouter >
        <ReactQueryDevtools />
      </QueryClientProvider >
    </StrictMode>
  )
}

App.whyDidYouRender = false

export default App