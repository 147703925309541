import Big from 'big.js'

Big.DP = 4
Big.RM = 1


type PriceValues = {
  subtotal: string
  discount: string
  tax: string
  quantity: string
  total: string
  discountRate: string
  taxRate: string
  base: string
}

class Price {
  private _price: Big
  private _taxRate: Big
  private _discountRate: Big
  private _quantity: Big

  // Calculated values
  private _tax = new Big(0.0)
  private _subtotal = new Big(0.0)
  private _total = new Big(0.0)
  private _discount = new Big(0.0)
  private _base = new Big(0.0)

  constructor(price = 0.0, tax = 0.0, discount = 0.0, quantity = 1.0) {
    this._price = new Big(price)
    this._quantity = new Big(quantity)
    this._taxRate = new Big(tax)
    this._discountRate = new Big(discount)
    this.calc()
  }

  get price(): Big { return this._price }
  get taxRate(): Big { return this._taxRate }
  get discountRate(): Big { return this._discountRate }
  get quantity(): Big { return this._quantity }

  get tax(): Big { return this._tax }
  get subtotal(): Big { return this._subtotal }
  get total(): Big { return this._total }
  get discount(): Big { return this._discount }
  get base(): Big { return this._base }

  set price(value: number | string | Big) {
    this._price = (value instanceof Big) ? value : new Big(value)
    this.calc()
  }
  set taxRate(value: number | string | Big) {
    this._taxRate = (value instanceof Big) ? value : new Big(value)
    this.calc()
  }
  set discountRate(value: number | string | Big) {
    this._discountRate = (value instanceof Big) ? value : new Big(value)
    this.calc()
  }
  set quantity(value: number | string | Big) {
    this._quantity = (value instanceof Big) ? value : new Big(value)
    this.calc()
  }

  calc = () => {
    this._subtotal = this._price.times(this._quantity)
    this._discount = this._subtotal.times(this._discountRate)
    this._base = this._subtotal.minus(this._discount)
    this._tax = this._base.times(this._taxRate)
    this._total = this._base.plus(this._tax)
  }

  values = (): PriceValues => ({
    quantity: this._quantity.toString(), //*  CANTIDAD
    subtotal: this._subtotal.toFixed(2), //*  PRECIO * CANTIDAD
    discount: this._discount.toFixed(2), //*  (PRECIO * CANTIDAD) * DESCUENTO = DESCUENTO TOTAL
    base: this._base.toFixed(2),         //*  SUBTOTAL - DESCUENTO = TOTAL SIN IMPUESTOS
    tax: this._tax.toFixed(2),           //*  BASE * TAX = IMPUESTO TOTAL
    total: this._total.toFixed(2),       //*  BASE + TAX = TOTAL CON IMPUESTOS

    discountRate: this._discountRate.times(100.0).toFixed(2),
    taxRate: this._taxRate.times(100.0).toFixed(2),
  })

}

export default Price