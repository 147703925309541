import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useStoreActions } from 'easy-peasy'

import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import sub from 'date-fns/sub'

import ocultableComponent from 'HOC/OcultableComponent'
import formatDateTimeAPI from 'utils/datetime/formatDateTimeAPI'

import facturasService from 'services/DataServices/facturasService'
import warehousesService from 'services/DataServices/warehousesService'

import styles from './styles.module.sass'
import { updateFactura } from 'pages/Facturas/fuerzaRecalculoFacturas'
import { AppState } from 'store/types'
import DateRangeSelector from 'components/DateRangeSelector'

import useFacturasActualizables from './useFacturasActualizables'

type RecalculoFacturacionDialogProps = {
  onClose: () => void
}


const FiltroFacturacionDialog: React.FC<RecalculoFacturacionDialogProps> = (props) => {
  const { onClose } = props

  const setAwaiting = useStoreActions<AppState>(actions => actions.setAwaiting)

  const hoy = new Date()

  const [condiciones, setCondiciones] = useState<any>({
    desde: startOfDay(sub(hoy, { days: 1 })),
    hasta: endOfDay(hoy)
  })
  const [facturas, almacenes] = useFacturasActualizables(condiciones)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [facturaProcesada, setFacturaProcesada] = useState<number>(0)


  const handleChangeFechas = (values: [Date, Date]) => {
    const newCondiciones = {
      desde: values[0],
      hasta: values[1]
    }

    setCondiciones(newCondiciones)
  }

  const fuerzaRecalculoFacturasEntreFechas = async () => {
    let p = 0
    for (let factura of facturas) {
      await updateFactura(factura, almacenes)
      p = p + 1
      setFacturaProcesada((prevState: number) => {
        return prevState + 1
      })
      console.log(`Factura procesada ${factura.ref}. (${p} de ${facturas.length})`)
    }
  }

  const handleSubmit = async () => {
    setAwaiting(true)
    setIsSubmitting(true)

    await fuerzaRecalculoFacturasEntreFechas()

    setAwaiting(false)
    setIsSubmitting(false)
    onClose()
  }

  return (
    <Modal show onHide={onClose} dialogClassName={styles.modal} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Filtrado de datos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='form-row'>
            <div className='col-md-6 offset-md-3'>
              <DateRangeSelector
                label='Elija el rango de fechas'
                classes='text-center'
                startDate={condiciones.desde}
                endDate={condiciones.hasta}
                maxDate={endOfDay(hoy)}
                onChange={handleChangeFechas}
                disabled={isSubmitting}
              />
            </div>
          </div>
          {
            (facturas.length > 0 && isSubmitting) ? (
              <>
                <div className='form-row'>
                  <div className="col text-center">
                    <span>{`Actualizando ${facturaProcesada} de ${facturas.length} facturas`}</span>
                  </div>
                </div>
                <div className='form-row'>
                  <div className="col">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${100 * facturaProcesada / facturas.length}%` }}
                        aria-valuenow={facturaProcesada}
                        aria-valuemin="0"
                        aria-valuemax={facturas.length}
                      />
                    </div>
                  </div>
                </div>
              </>

            ) : null

          }
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-danger"
          onClick={onClose} disabled={isSubmitting}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={isSubmitting || (!Boolean(condiciones.desde)) || (!Boolean(condiciones.hasta))}
          onClick={handleSubmit}
        >
          Aceptar
        </button>
      </Modal.Footer>
    </Modal >
  )
}

export default ocultableComponent(FiltroFacturacionDialog)