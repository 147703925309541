import { NavLink } from 'react-router-dom'

import styles from './styles.module.sass'
import { useStoreState } from 'store'
import verifyGroupAllowed from 'utils/verifyGroupAllowed'


type MenuItemExtraInfo = {
  items: number
}


type MenuItemProps = {
  route: string
  icon: string
  text: string
  groups?: string[] // Grupos permitidos
  extra?: MenuItemExtraInfo
}

const MenuItemItemsCount: React.FC<any> = (props) => (
  <span className="ml-1 badge badge-danger">
    {props.items}
  </span>
)

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const permissions = useStoreState(state => state.permissions)

  const allowed = permissions.is_superuser || verifyGroupAllowed(props.groups, permissions.groupNames)
  const items = parseInt(props.extra?.items || '0')

  return (
    allowed && (
      <li>
        {/* <li className={styles.menuItem}> */}
        <NavLink
          to={props.route}
          activeClassName={styles.active}
          exact={props.route === '/' ? true : false}
        >
          <i className={`${props.icon} ${styles.leftIcon}`} />
          {props.text}
          {/* <i className="fa fa-caret-left fa-lg" /> */}
          {
            Boolean(items) && <MenuItemItemsCount items={items} />
          }
        </NavLink>
      </li>
    )
  )
}


export default MenuItem