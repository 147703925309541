import { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'

import BaseLayout from '../BaseLayout'
import { useStoreState } from 'store'


const LoginPage = lazy(() => import('pages/Login'))

const HomePage = lazy(() => import('pages/Home'))

const InventoryPage = lazy(() => import('pages/Inventory'))
const EditMachinePage = lazy(() => import('pages/Maquina/EditMachine'))
const NewMachinePage = lazy(() => import('pages/Maquina/NewMachine'))

const CustomersPage = lazy(() => import('pages/Clientes'))
const NewCustomerPage = lazy(() => import('pages/Clientes/NewCliente'))
const EditCustomerPage = lazy(() => import('pages/Clientes/EditCliente'))

const BuildingsPage = lazy(() => import('pages/Obras'))
const NewBuildingPage = lazy(() => import('pages/Obras/NewObra'))
const EditBuildingPage = lazy(() => import('pages/Obras/EditObra'))

const FamiliesPage = lazy(() => import('pages/Maintenance/Familias'))
const NewFamilyPage = lazy(() => import('pages/Maintenance/Familias/NewFamily'))
const EditFamilyPage = lazy(() => import('pages/Maintenance/Familias/EditFamily'))

const SubfamiliesPage = lazy(() => import('pages/Maintenance/Subfamilias'))
const NewSubfamilyPage = lazy(() => import('pages/Maintenance/Subfamilias/NewSubfamily'))
const EditSubfamilyPage = lazy(() => import('pages/Maintenance/Subfamilias/EditSubfamily'))

const BancosPage = lazy(() => import('pages/Maintenance/Bancos'))
const NewBancoPage = lazy(() => import('pages/Maintenance/Bancos/NewBanco'))
const EditBancoPage = lazy(() => import('pages/Maintenance/Bancos/EditBanco'))

const BudgetsPage = lazy(() => import('pages/Ofertas/index'))
const NewBudgetPage = lazy(() => import('pages/Ofertas/NewOferta'))
const EditOfertaPage = lazy(() => import('pages/Ofertas/EditOferta'))

const AlbaranesPage = lazy(() => import('pages/Albaranes/index'))
const NewAlbaranPage = lazy(() => import('pages/Albaranes/NewAlbaran'))
const EditAlbaranPage = lazy(() => import('pages/Albaranes/EditAlbaran'))

const FacturasPage = lazy(() => import('pages/Facturas/index'))
const NewFacturaPage = lazy(() => import('pages/Facturas/NewFactura'))
const EditFacturaPage = lazy(() => import('pages/Facturas/EditFactura'))
const NewFacturasGrupo = lazy(() => import('pages/Facturas/NewFacturasGrupo'))

const NewContractPage = lazy(() => import('pages/Contratos/NewContrato'))
const EditContractPage = lazy(() => import('pages/Contratos/EditContrato'))

// const TallerIndex = lazy(() => import('pages/Taller'))

const AccesoriosPage = lazy(() => import('pages/Accesorios'))
const NewAccesorioPage = lazy(() => import('pages/Accesorios/NewAccesorio'))
const EditAccesorioPage = lazy(() => import('pages/Accesorios/EditAccesorio'))

const InformesPage = lazy(() => import('pages/Informes'))
const PrevisionFacturacionMensual = lazy(() => import('pages/Informes/PrevisionFacturacionMensual'))
const EstadoInventario = lazy(() => import('pages/Informes/EstadoInventario'))
const FacturacionReport = lazy(() => import('pages/Informes/FacturacionReport'))
const IGICReport = lazy(() => import('pages/Informes/IGICReport'))
const CobrosReport = lazy(() => import('pages/Informes/CobrosReport'))
const ReclamarCobroReport = lazy(() => import('pages/Informes/ReclamarCobroReport'))


const UsuariosPage = lazy(() => import('pages/Usuarios'))
const NewUsuarioPage = lazy(() => import('pages/Usuarios/NewUsuario'))
//const EditTecnicoPage = lazy(() => import('pages/Tecnicos/EditTecnico'))

const TraspasosPage = lazy(() => import('pages/Traspasos'))
const NewTraspaso = lazy(() => import('pages/Traspasos/NewTraspaso'))
const EditTraspaso = lazy(() => import('pages/Traspasos/EditTraspaso'))


const FestivosPage = lazy(() => import('pages/Festivos'))
const ImpuestosPage = lazy(() => import('pages/Impuestos'))


const Routes = () => {
  const isLogged = useStoreState(state => state.isLogged)

  return (
    !isLogged ? (
      <Switch>
        <Route component={LoginPage} path="/" />
      </Switch>
    ) : (
      <BaseLayout>
        <Switch>
          <Route component={HomePage} path="/" exact />

          <Route component={InventoryPage} path="/inventory" exact />
          <Route component={NewMachinePage} path="/machine/new" exact />
          <Route component={NewMachinePage} path="/generico/new" exact />
          <Route component={EditMachinePage} path="/machine/:id" />

          <Route component={CustomersPage} path="/customers" exact />
          <Route component={NewCustomerPage} path="/customer/new" exact />
          <Route component={EditCustomerPage} path="/customer/:id" />

          <Route component={BuildingsPage} path="/buildings" exact />
          <Route component={NewBuildingPage} path="/building/new" exact />
          <Route component={EditBuildingPage} path="/building/:id" />

          <Route component={BudgetsPage} path="/budgets" exact />
          <Route component={NewBudgetPage} path="/budget/newsell" exact />
          <Route component={NewBudgetPage} path="/budget/newrental" exact />
          <Route component={EditOfertaPage} path="/budget/:id" />

          <Route component={AlbaranesPage} path="/albaranes" exact />
          <Route component={NewAlbaranPage} path="/albaran/newsell" exact />
          <Route component={NewAlbaranPage} path="/albaran/newrental" exact />
          <Route component={EditAlbaranPage} path="/albaran/:id" exact />

          <Route component={FacturasPage} path="/facturas" exact />
          <Route component={NewFacturaPage} path="/factura/newsell" exact />
          <Route component={NewFacturaPage} path="/factura/newrental" exact />
          <Route component={NewFacturasGrupo} path="/factura/newgrouprental" exact />
          {/* ** https://stackoverflow.com/questions/67731146/react-router-link-is-not-re-rendering-component */}
          <Route component={(props) => <EditFacturaPage key={props.match.params.id} {...props} />} path="/factura/:id" exact />

          <Route component={BancosPage} path="/bancos" />
          <Route component={NewBancoPage} path="/banco/new" exact />
          <Route component={EditBancoPage} path="/banco/:id" />

          <Route component={FamiliesPage} path="/familias" />
          <Route component={NewFamilyPage} path="/familia/new" exact />
          <Route component={EditFamilyPage} path="/familia/:id" />

          <Route component={SubfamiliesPage} path="/subfamilias" />
          <Route component={NewSubfamilyPage} path="/subfamilia/new" exact />
          <Route component={EditSubfamilyPage} path="/subfamilia/:id" />

          <Route component={NewContractPage} path="/contract/new" exact />
          <Route component={EditContractPage} path="/contract/:id" />

          {/* <Route component={TallerIndex} path="/taller" /> */}

          <Route component={AccesoriosPage} path="/accesorios" />
          <Route component={NewAccesorioPage} path="/accesorio/new" exact />
          <Route component={EditAccesorioPage} path="/accesorio/:id" />

          <Route component={InformesPage} path="/informes" exact />
          <Route component={EstadoInventario} path="/informes/estado-inventario" exact />
          <Route component={PrevisionFacturacionMensual} path="/informes/prev-fact-mensual" exact />
          <Route component={FacturacionReport} path="/informes/facturacion" exact />
          <Route component={IGICReport} path="/informes/igic" exact />
          <Route component={CobrosReport} path="/informes/cobros" exact />
          <Route component={ReclamarCobroReport} path="/informes/reclamarCobro" exact />

          <Route component={UsuariosPage} path="/usuarios" />
          <Route component={NewUsuarioPage} path="/usuario/new" exact />

          <Route component={FestivosPage} path="/festivos" />
          <Route component={ImpuestosPage} path="/impuestos" />

          <Route component={TraspasosPage} path="/traspasos" />
          <Route component={NewTraspaso} path="/traspaso/new" exact />
          <Route component={EditTraspaso} path="/traspaso/:id" />

          <Route component={HomePage} path="*" />
        </Switch>
      </BaseLayout>
    )
  )
}

Routes.whyDidYouRender = false

export default Routes