import axios, { AxiosPromise } from 'axios'
import jwtRepository from 'infrastructure/repository/jwtRepository'

import { apiURL, apiAuthURL } from 'settings'

import apiConsoleError from './apiConsoleError'
import { LoginData } from 'services/apiService/types'


export class APIService<T> {
  apiURL: string = ''

  constructor(apiURL?: string) {
    this.apiURL = apiURL
  }

  headers = (auth = false) => {
    const jwt = jwtRepository.getToken()
    // console.log('JWT', jwt)
    const newHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `JWT ${jwt}`,
    }

    if (auth) { delete newHeaders.Authorization }

    return newHeaders
  }

  create = (model: string, params: any = {}): AxiosPromise<T> => (
    axios({
      url: `${this.apiURL}/${model}/`,
      method: 'POST',
      headers: this.headers(),
      data: params,
    })
  )

  read = (model: string, id: string | number | null = null, params = {}): AxiosPromise<T> => {
    const urlArg = id ? `/${id}/` : '/'
    return axios({
      url: `${this.apiURL}/${model}${urlArg}`,
      method: 'GET',
      headers: this.headers(),
      params,
    })
  }

  list = (model: string, params = {}): AxiosPromise<T[]> => {
    return axios({
      url: `${this.apiURL}/${model}/`,
      method: 'GET',
      headers: this.headers(),
      params,
    })
  }

  pagedList = (model: string, params = {}): AxiosPromise<DjangoPagedResponse<T>> => {
    return axios({
      url: `${this.apiURL}/${model}/`,
      method: 'GET',
      headers: this.headers(),
      params,
    })
  }

  edit = (model: string, id: string | number, params = {}): AxiosPromise<T> => {
    return axios({
      url: `${this.apiURL}/${model}/${id}/`,
      method: 'PATCH',
      headers: this.headers(),
      data: params,
    })
  }


  del = (model: string, id: string | number, params: any = {}): AxiosPromise<T> => (
    axios({
      url: `${this.apiURL}/${model}/${id}/`,
      method: 'DELETE',
      headers: this.headers(),
      data: params,
    })
  )

  search = (model: string, searchString: string): AxiosPromise<T[]> => {
    return axios({
      url: `${this.apiURL}/${model}/?${searchString}`,
      method: 'GET',
      headers: this.headers(),
      //params,
    })
  }

  auth = (loginData: LoginData) => (
    axios({
      url: `${apiAuthURL}`,
      method: 'POST',
      headers: this.headers(true),
      data: loginData,
    })
  )
}

const apiService: APIService<any> = new APIService(apiURL)

export { apiConsoleError }

export default apiService
