import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isSunday from 'date-fns/isSunday'
import isWeekend from 'date-fns/isWeekend'

import getDaysArray from './getDateArray'
import getDiasFestivos from './getDiasFestivos'

import { TipoFacturacionMaquina } from 'data/Maquina'


type getDiasAlquilerParams = {
  venta: boolean
  alquiladoDesde: Date
  alquiladoHasta: Date
  minDiasFacturables: number
  tipoFacturacion: TipoFacturacionMaquina
  festivos: (Date | string)[]
  inicioFacturacion: Date
  finFacturacion: Date
  sabadosHabiles: boolean
}


const getDiasAlquilerFactura = (params: getDiasAlquilerParams): number => {
  // ** Sólo se tienen en cuenta los festivos en caso de facturación por 
  // ** día laborable
  /**
    Si Facturación por día laboral:
      si los sabados son hábiles
        sumar los sabados (ya que differenceInBusinessDays los descuenta) y descontar los festivos
      si no:
        descontar los festivos que no son sábado

    Si Facturación por dia natural:
      se cuentan todos los dias (differenceInCalendarDays)
      no descontar ningún festivo
  **/

  if (params.venta) return 1  // SALIDA RÁPIDA
  if (!!!params.alquiladoDesde) return 0

  const _alquiladoDesde = params.alquiladoDesde
  const _alquiladoHasta = params.alquiladoHasta
  const _inicioFacturacion = startOfDay(params.inicioFacturacion)
  const _finFacturacion = endOfDay(params.finFacturacion)

  // -- Si llega a aquí es un alquiler y al menos la fecha desde está establecida --
  let __desde = startOfDay(_alquiladoDesde)
  let __hasta = endOfDay(_alquiladoHasta || _finFacturacion)
  const LABORABLES = params.tipoFacturacion === TipoFacturacionMaquina.Laboral

  // differenceInBusinessDays: Descuenta sábados y domingos, hay que sumar 1 dia
  // differenceInCalendarDays: Cuenta todos los dias del rango, hay que sumar 1 dia
  //const differenceDays = LABORABLES ? differenceInBusinessDays : differenceInCalendarDays

  if (isBefore(_alquiladoDesde, _inicioFacturacion)) { __desde = _inicioFacturacion }
  if (isAfter(_alquiladoHasta, _finFacturacion)) { __hasta = _finFacturacion }

  // Los sábados hábiles del rango de dias, si es facturación por dia laborable
  const diasAlquilados = getDaysArray(__desde, __hasta).filter(dia => {
    if (LABORABLES) {
      if (params.sabadosHabiles) { return !isSunday(dia) }
      return !isWeekend(dia)
    }
    return true
  })

  // console.log('C', LABORABLES, diasAlquilados.length)

  // const sabadosHabilesArray = LABORABLES ? getDaysArray(__desde, __hasta).filter(dia => (Boolean(params.sabadosHabiles) && isSaturday(dia))) : []

  // Los dias festivos, descontando los sábados si estos no son hábiles,
  // ya que ya estarían descontados

  const diasFestivosArray = LABORABLES ? getDiasFestivos(params.festivos, __desde, __hasta).filter(dia => {
    if (Boolean(params.sabadosHabiles)) {
      return true
    }

    return !isWeekend(dia)
  }) : []

  // Sábados a sumar en caso de que sean hábiles
  //const numSabadosHabiles = sabadosHabilesArray.length
  // Festivos a descontar
  const numDiasFestivos = diasFestivosArray.length

  //const dias = differenceDays(__hasta, __desde) - numDiasFestivos + numSabadosHabiles

  const dias = diasAlquilados.length - numDiasFestivos
  //console.log('>>', dias, params.sabadosHabiles, sabadosHabilesArray)
  let totalDiasAlquiler = 0
  if (dias <= 0) {
    // * Esto pasa si no tiene dias en el rango de fechas de facturación
    // TODO: Refactorizar filtrándolo desde el principio
    totalDiasAlquiler = 0
  } else {
    totalDiasAlquiler = (params.minDiasFacturables > dias) ? params.minDiasFacturables : dias
  }

  return totalDiasAlquiler
}


export default getDiasAlquilerFactura