import React from 'react'


const ErrorPage = ({ error, componentStack, resetErrorBoundary }) => {
  console.log(error)
  console.log(componentStack)
  return (
    <div>
      <h1>Se ha producido un error: {error.message}</h1>
      <h3>{componentStack}</h3>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export default ErrorPage