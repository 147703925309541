import { apiURL } from 'settings'
import { APIService } from 'services/apiService'
import Exception from 'services/apiService/APIServiceException'
import { AxiosResponse, AxiosError } from 'axios'

interface ModelAPIServiceResponse<T> {
  response: AxiosResponse<T> | null
  error: AxiosError | null
}


class ModelAPIService<T> {
  model: string = ''
  apiService: APIService<T> | null = null
  modelName: string = ''

  constructor(model: string, modelName?: string) {
    this.model = model
    this.apiService = new APIService<T>(apiURL)
    this.modelName = modelName ? modelName : model
  }


  private responseAdapter<T>(res: AxiosResponse<T> | AxiosError<T>): ModelAPIServiceResponse<T> {
    // console.log({ res })
    const response = Boolean((res as AxiosError).isAxiosError) ? null : res as AxiosResponse<T>
    const error = Boolean((res as AxiosError).isAxiosError) ? res as AxiosError<T> : null
    const result = { response, error }

    return result
  }


  create = async (item: T) => {
    try {
      const res = await this.apiService.create(this.model, item)
      return res
    } catch (err) {
      throw new Exception(`${this.model.toLocaleUpperCase()} creation error`, `No se pudo crear <${this.modelName}>.`, err, item)
    }
  }

  delete = async (id: ID) => {
    try {
      const res = await this.apiService.del(this.model, id)
      return res
    } catch (err) {
      throw new Exception(`${this.model.toLocaleUpperCase()} creation error`, `No se pudo crear <${this.modelName}>.`, err, id)
    }
  }

  createNew = async (item: T): Promise<ModelAPIServiceResponse<T>> => {
    const res = await this.apiService.create(this.model, item).catch(error => error)
    const result = this.responseAdapter<T>(res)
    return result
  }

  patch = async (id: ID, item: T) => {
    let res: AxiosResponse<T>
    try {
      res = await this.apiService.edit(this.model, id, item)
      return res
    } catch (err) {
      throw new Exception(`${this.model.toLocaleUpperCase()} patch error`, `No se pudo modificar <${this.modelName}:${id}>.`, err, item)
    }
  }

  patchNew = async (id: ID, item: T): Promise<ModelAPIServiceResponse<T>> => {
    let res
    try {
      res = await this.apiService.edit(this.model, id, item).catch(error => error)
    } catch (err) {
      res = err
    }
    const result = this.responseAdapter<T>(res)
    return result
  }

  fetch = async (id: ID): Promise<T> => {
    try {
      const res = await this.apiService.read(this.model, id)
      return res.data
    } catch (err) {
      throw new Exception(`${this.model.toLocaleUpperCase()} fetch error`, `No se pudo obtener <${this.modelName}:${id}>.`, err, id)
    }
  }

  list = async (filter: object = {}): Promise<T[]> => {
    try {
      const res = await this.apiService.list(this.model, filter)
      return res.data
    } catch (err) {
      throw new Exception(`${this.model.toLocaleUpperCase()} list fetch error`, `No se pudo obtener el listado <${this.modelName}>.`, err, filter)
    }
  }

  pagedList = async (page: ID = 1, filter: object = {}): Promise<DjangoPagedResponse<T>> => {
    const queryParams = Object.assign({}, filter, { page })
    try {
      const res = await this.apiService.pagedList(this.model, queryParams)
      return res.data
    } catch (err) {
      const exception = new Exception(
        `${this.modelName.toLocaleUpperCase()} paged list fetch error`,
        `No se pudo obtener el listado paginado <${this.modelName}>.`,
        err, queryParams
      )
      throw exception
    }
  }

  search = async (value: string = ''): Promise<T[]> => {
    try {
      const res = await this.apiService.list(this.model, { search: encodeURIComponent(value) })
      return res.data
    } catch (err) {
      throw new Exception(`${this.model.toLocaleUpperCase()} search fetch error`, `No se pudo obtener la búsqueda <${this.modelName}>, <${value}>.`, err, value)
    }
  }
}


export default ModelAPIService