/*
 * Retorna un array de fechas (Date) entre dos fechas
 */

const getDaysArray = (start: Date, end: Date): Date[] => {
  for (
    var array = [], date = new Date(start);
    date <= new Date(end);
    date.setDate(date.getDate() + 1)
  ) {
    array.push(new Date(date))
  }

  return array
}


export default getDaysArray