import { AppState } from './types'
import { createStore, action, computed } from 'easy-peasy'
import { startOfMonth, endOfMonth } from 'date-fns'

import {
  refreshUserData,
  refreshTraspasosPendientesRecepcion,
  logout
} from './thunks'


export const GROUPS = {
  DEVS: 'DEVS',
  GESTOR_1: 'GESTOR 1',
  GESTOR_1B: 'GESTOR 1B',
  GESTOR_2: 'GESTOR 2',
  COMERCIAL: 'COMERCIAL',
  TECNICO: 'TECNICO',
}


const getGroupName = (group: DjangoGroup): string => {
  const groupName = Boolean(group.name) ? group.name.toUpperCase() : ''

  return groupName
}

const permissions = computed<AppState, DjangoUserPermissions | null>(state => {
  const { user } = state
  const permissions: DjangoUserPermissions = {
    group: (user && user.group_name) ? user.group_name.toUpperCase() : '',
    groups: user ? user.groups.map(getGroupName) : [],
    groupNames: user ? user.groupNames.map(g => g.toUpperCase()) : [],
    is_staff: Boolean(user?.is_staff),
    is_active: Boolean(user?.is_active),
    is_superuser: Boolean(user?.is_superuser),
    GROUPS
  }

  return permissions
})



const store = createStore<AppState>({
  user: null,
  jwt: null,
  loging: false,
  refreshingUserData: false,
  warehouse: null,
  warehouses: [],
  festivos: [],
  warehouseLocked: false,
  awaiting: false,
  printing: false,
  permissions,
  traspasosPendientesRecepcion: 0,
  isLogged: computed((state) => Boolean(state.jwt)),
  maquinaAccesorioSearchFilter: '',
  filtroListadoInventario: {
    ordering: 'fleetNumber',
    venta: false, active: true, family: 0,
    family__subfamily: 0, subfamily: 0, generico: false
  },
  filtroListadoAccesorios: { ordering: 'referencia' },
  filtroListadoClientes: { active: true, ordering: 'name' },
  filtroListadoOfertas: {
    ordering: '-budgetYear,-budgetNumber',
    budgetYear: (new Date()).getFullYear().toString(),
    sale: false,
    taller: false
  },
  filtroListadoAlbaranes: {
    ultimaVersion: true,
    ordering: '-ano,-numero,-id',
    venta: false,
    ano: (new Date()).getFullYear().toString(),
    creado__month: ((new Date()).getMonth() + 1).toString()
  },
  filtroListadoFacturas: { ordering: '-ano,-numero,-id', venta: false, ano: (new Date()).getFullYear().toString() },
  filtroListadoObras: { status: 100 /* EN CURSO */ },
  filtroListadoTraspasos: {
    ordering: '-fechaCreado,-fechaEnvio,-fechaRecepcion'
  },
  filtroFacturacionReport: {
    ano: new Date().getFullYear(),
    mes: new Date().getMonth() + 1,
    almacen: 'todos',
    cliente: null,
    obra: null,
    metodoPago: null,
    tipo: 1, // 1-Alquiler, 2-Venta, 3-Taller
    cobros: 0,
    documentadas: 0,
    vencimiento: 0,
    comercial: '',
    abonos: 'todos',
  },
  filtroIGICReport: {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    almacen: 'todos',
    cliente: null,
  },
  filtroCobrosReport: {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    almacen: 'todos',
    formaCobro: 'Todos',
    cliente: null,
  },
  filtroReclamarCobroReport: {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    almacen: 'todos',
    formaCobro: 'Todos',
    cliente: null
  },


  clienteEditSection: {
    idCliente: 0,
    desdeAlbaran: false,
    desdeFactura: false,
    tabKey: "datos",
    selectedFacturas: [],
    selectedFacturasPagadas: [],
    selectedFacturasPendientes: [],
    selectedFacturasDocumentadas: [],
    selectedFacturasVencidas: []
  },

  clienteEditClearState: action<AppState, number>((state, idCliente) => {
    console.log('..clienteEditClearState')
    state.clienteEditSection.idCliente = idCliente
    state.clienteEditSection.desdeAlbaran = false
    state.clienteEditSection.desdeFactura = false
    state.clienteEditSection.tabKey = "datos"
    state.clienteEditSection.selectedFacturas = []
    state.clienteEditSection.selectedFacturasPagadas = []
    state.clienteEditSection.selectedFacturasPendientes = []
    state.clienteEditSection.selectedFacturasDocumentadas = []
    state.clienteEditSection.selectedFacturasVencidas = []
  }),
  clienteEditSetDesdeFactura: action<AppState, boolean>((state, desdeFactura: boolean) => {
    state.clienteEditSection.desdeFactura = desdeFactura
  }),
  clienteEditSetTabKey: action<AppState, string>((state, newTabKey) => {
    state.clienteEditSection.tabKey = newTabKey
  }),
  clienteEditSetSelectedFacturas: action<AppState, FacturaAPI[]>((state, facturas: FacturaAPI[]) => {
    state.clienteEditSection.selectedFacturas = facturas
  }),
  clienteEditSetSelectedFacturasPagadas: action<AppState, ID[]>((state, facturas: ID[]) => {
    state.clienteEditSection.selectedFacturasPagadas = facturas
  }),
  clienteEditSetSelectedFacturasPendientes: action<AppState, ID[]>((state, facturas: ID[]) => {
    state.clienteEditSection.selectedFacturasPendientes = facturas
  }),
  clienteEditSetSelectedFacturasDocumentadas: action<AppState, ID[]>((state, facturas: ID[]) => {
    state.clienteEditSection.selectedFacturasDocumentadas = facturas
  }),
  clienteEditSetSelectedFacturasVencidas: action<AppState, ID[]>((state, facturas: ID[]) => {
    state.clienteEditSection.selectedFacturasVencidas = facturas
  }),


  setMaquinaAccesorioSearchFilter: action<AppState, string>((state, newFilter) => { state.maquinaAccesorioSearchFilter = newFilter }),
  setListadoInventario: action((state, newFilter) => {
    state.filtroListadoInventario = newFilter
  }),
  setListadoAccesorios: action((state, newFilter) => { state.filtroListadoAccesorios = newFilter }),
  setListadoClientes: action((state, newFilter) => { state.filtroListadoClientes = newFilter }),
  setListadoOfertas: action((state, newFilter) => { state.filtroListadoOfertas = newFilter }),
  setListadoAlbaranes: action((state, newFilter) => { state.filtroListadoAlbaranes = newFilter }),
  setListadoFacturas: action((state, newFilter) => { state.filtroListadoFacturas = newFilter }),
  setListadoObras: action((state, newFilter) => { state.filtroListadoObras = newFilter }),
  setFiltroListadoTraspasos: action((state, newFilter) => { state.filtroListadoTraspasos = newFilter }),
  setFiltroFacturacionReport: action((state, newFilter: FiltroFacturacionReportParams) => { state.filtroFacturacionReport = newFilter }),
  setFiltroIGICReport: action((state, newFilter: FiltroFacturacionReportParams) => { state.filtroIGICReport = newFilter }),
  setFiltroCobrosReport: action((state, newFilter: any) => { state.filtroCobrosReport = newFilter }),
  setFiltroReclamarCobroReport: action((state, newFilter: any) => { state.filtroReclamarCobroReport = newFilter }),
  setUser: action((state, user) => { state.user = user }),
  setJWT: action((state, jwt) => { state.jwt = jwt }),
  setLoging: action((state, payload) => { state.loging = payload }),
  setPrinting: action((state, printing) => { state.printing = Boolean(printing) }),
  setAwaiting: action((state, awaiting) => { state.awaiting = Boolean(awaiting) }),
  setRefreshingUserData: action((state, payload) => { state.refreshingUserData = payload }),
  refreshUserData,
  setTraspasosPendientesRecepcion: action((state, payload) => { state.traspasosPendientesRecepcion = payload }),
  refreshTraspasosPendientesRecepcion,
  setWarehouses: action((state, almacenes) => { state.warehouses = almacenes }),
  setWarehouse: action((state, almacen) => { state.warehouse = almacen }),
  setFestivos: action((state, festivos) => { state.festivos = festivos || [] }),
  setWarehouseLocked: action((state, payload) => { state.warehouseLocked = payload }),
  logout
})

export default store