import './wdyr'  // Why did you render
import { render } from 'react-dom'
import { StoreProvider } from 'easy-peasy'
import reportWebVitals from './reportWebVitals'


import App from './App'
import store from './store'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-tooltip/dist/react-tooltip.css'
import './index.sass'
import 'styles/card.sass'
import 'styles/cursors.sass'

//TODO: Eliminar después de que Ofertas migre su impresión al PrinterService
import './components/Printer/handlebarsHelpers'

// window.DEBUG__ENABLED = true

render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>,
  document.getElementById('root')
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
