//import secureStorage from '../SecureStorage'
//import localRepository from './LocalRepository'


const getToken = (): string => {
  // const token = secureStorage.getItem('token')
  const token = localStorage.getItem('token')
  return token
}

const setToken = (token: string): void => {
  //secureStorage.setItem('token', token)
  localStorage.setItem('token', token)
}

const clearToken = (): void => {
  //secureStorage.clear('token')
  localStorage.removeItem('token')
}


const jwtRepository = {
  getToken,
  setToken,
  clearToken
}

export default jwtRepository

