import parseISO from 'date-fns/parseISO'

const parseDateTimeAPI = (dateTime: string | null | Date): Date | null => {
  let result = null

  if (dateTime instanceof Date) {
    result = dateTime
  } else {
    result = dateTime ? parseISO(dateTime) : null
  }

  return result
}


export default parseDateTimeAPI