const ensureString = (value: any, converter?: any): string => {
  const type = typeof value
  // console.log(value, type)
  if (type === 'number') return Number(value).toString()
  if (type === 'string') return value

  return ''
  // throw Error(`El tipo de dato no está contemplado: ${type}!!`)
}

export default ensureString