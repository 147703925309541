import LineaFacturaClass from './LineaFacturaClass'
import Price from 'finances/price'
import getDiasAlquilerFactura from 'utils/date/getDiasAlquilerFactura'
import ensureString from 'utils/ensureString'
import parse from 'date-fns/parse'

type LineaFacturaCalculatorParams = {
  festivos: (Date | string)[],
  factura: FacturaAPI
}


class LineaFacturaCalculator extends LineaFacturaClass {

  calculate = (params: LineaFacturaCalculatorParams): Price => {
    const { festivos, factura } = params

    const diasAlquiler = getDiasAlquilerFactura({
      venta: factura.venta,
      alquiladoDesde: this.data.desde,
      alquiladoHasta: this.data.hasta,
      minDiasFacturables: this.data.minDiasFacturables || 1,
      tipoFacturacion: this.data.tipoFacturacion,
      festivos,
      inicioFacturacion: parse(factura.inicioFactura, 'yyyy-MM-dd', new Date()),
      finFacturacion: parse(factura.finFactura, 'yyyy-MM-dd', new Date()),
      sabadosHabiles: factura.sabadosHabiles,
    })

    // console.log('-->', Math.random(), diasAlquiler, this.data.desde, this.data.hasta, factura.inicioFactura, factura.finFactura, factura.sabadosHabiles)

    const cantidad = parseFloat(ensureString(this.data.cantidad)) * (diasAlquiler * 1.0)
    const discountRate = parseFloat(this.data.descuento) / 100.0
    const taxRate = parseFloat(this.data.impuesto) / 100.0
    const precio = parseFloat(this.data.precio)

    const totaler = new Price(precio, taxRate, discountRate, cantidad)

    this.data.diasAlquiler = diasAlquiler.toString()
    this.data.values = totaler.values()

    return totaler
  }
}


export default LineaFacturaCalculator