import DataClass from '../DataClass'
import objectIDOrNull from 'utils/objectIDOrNull'
import ESTADOS from './EstadosFactura'
import parseDateAPI from 'utils/datetime/parseDateAPI'
import clone from 'utils/clone'


class FacturaClass extends DataClass<Factura> {
  data: Factura
  static ESTADOS = ESTADOS

  constructor(factura?: FacturaAPI) {
    super()
    this.data = this.normalize(factura)

  }

  normalize = (data?: FacturaAPI): Factura => {
    let factura: Factura = FacturaClass.getDefaultValues()

    if (data) {
      factura = {
        id: data.id,
        ano: data.ano,
        numero: data.numero,
        venta: data.venta,
        contado: data.contado,
        estado: data.estado,
        observaciones: data.observaciones,
        grupo: data.grupo,
        grupoMarca: data.grupoMarca,
        sabadosHabiles: data.sabadosHabiles,
        inicioFactura: parseDateAPI(data.inicioFactura),
        finFactura: parseDateAPI(data.finFactura),
        cliente: objectIDOrNull(data.cliente),
        almacen: objectIDOrNull(data.almacen),
        contrato: objectIDOrNull(data.contrato),
        albaran: objectIDOrNull(data.albaran),
        facturaAbonada: data.facturaAbonada,
        contratoDetail: data.contrato,
        almacenDetail: data.almacen,
        clienteDetail: data.cliente,
        albaranDetail: data.albaran,
        numeroPedido: data.numeroPedido,
        obraDetail: data.obra,
        apiData: clone(data),
        abono: data.abono,
        abonos: data.abonos,
        abonoCompletado: data.abonoCompletado,
        fechaEmision: parseDateAPI(data.fechaEmision),
        albaranes: data.albaranes,
        facturaMultialbaran: data.facturaMultialbaran,
        metodoPago: data.metodoPago,
        metodoPagoString: data.metodoPagoString || '',
        festivos: data.festivos ? data.festivos.map(festivo => parseDateAPI(festivo)) : [],
        vencida: Boolean(data.vencida),
        vencimiento: data.vencimiento ? parseDateAPI(data.vencimiento) : new Date('3000-12-31T23:59:59'),
        cerrada: data.cerrada,
        subtotal: data.subtotal,
        descuento: data.descuento,
        impuestos: data.impuestos,
        total: data.total,
        calculada: data.calculada,
        ref: data.ref,
        fechaUltimoCobro: data.fechaUltimoCobro
      }

      try {
        factura.agrupacionImpuestos = JSON.parse(data.agrupacionImpuestos)
      } catch (e) {
        factura.agrupacionImpuestos = []
      }

      this.apiData = data
    }

    return factura
  }

  static getDefaultValues = (sabadosHabiles = false): Factura => {
    const factura: Factura = {
      cliente: null,
      almacen: null,
      contrato: null,
      albaran: null,
      venta: true,
      contado: false,
      estado: FacturaClass.ESTADOS.CREADA,
      grupo: false,
      grupoMarca: '',
      sabadosHabiles: Boolean(sabadosHabiles),
      inicioFactura: null,
      finFactura: null,
      observaciones: '',
      contratoDetail: null,
      almacenDetail: null,
      clienteDetail: null,
      albaranDetail: null,
      obraDetail: null,
      apiData: null,
      abono: false,
      facturaAbonada: null,
      abonos: [],
      abonoCompletado: false,
      fechaEmision: null,
      albaranes: [],
      facturaMultialbaran: false,
      metodoPago: null,
      metodoPagoString: '',
      vencida: false,
      vencimiento: new Date('3000-12-31T23:59:59'),
      calculada: false,
      fechaUltimoCobro: null,
      numeroPedido: ''
    }

    return factura
  }
}


export default FacturaClass