import isArray from './isArray'
import isNull from './isNull'

const isObject = (data: any): boolean => {
  if (typeof data !== 'object') { return false }
  if (isArray(data)) { return false }
  if (isNull(data)) { return false }

  return true
}

export default isObject