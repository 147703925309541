import { thunk } from 'easy-peasy'


import jwtRepository from 'infrastructure/repository/jwtRepository'
import AuthService from 'services/apiService/AuthService'
import warehousesService from 'services/DataServices/warehousesService'




export const refreshUserData = thunk<AppState, void>(async (actions, payload) => {
  const { setRefreshingUserData, setJWT, setUser } = actions
  const authService = new AuthService()

  setRefreshingUserData(true)
  try {
    const me = await authService.me()
    if (me) {
      const token = jwtRepository.getToken()
      setUser(me)
      setJWT(token)
    } else {
      jwtRepository.clearToken()
      setJWT(null)
      setUser(null)
    }
  } catch (err) {
    jwtRepository.clearToken()
    setJWT(null)
    setUser(null)
  } finally {
    setRefreshingUserData(false)
  }
})


export const refreshTraspasosPendientesRecepcion = thunk<AppState, ID>(async (actions, almacenID: ID) => {
  const { setTraspasosPendientesRecepcion } = actions
  let res = null

  if (almacenID) {
    try {
      res = await warehousesService.fetch(almacenID)
      setTraspasosPendientesRecepcion(res.traspasosPendientesRecepcion)
    } catch (err) {
      console.error(err)
      setTraspasosPendientesRecepcion(0)
    }
  } else {
    setTraspasosPendientesRecepcion(0)
  }
})


export const logout = thunk<AppState, void>(async (actions, payload) => {
  const { setJWT, setUser } = actions
  jwtRepository.clearToken()
  setJWT(null)
  setUser(null)
})
