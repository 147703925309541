import MenuItem from './MenuItem'
import styles from './styles.module.sass'
import { getAppVersion } from 'settings'
import { useStoreState } from 'easy-peasy'

import RecalculoFacturacionDialog from './RecalculoFacturacionDialog'
import { useToggle } from 'react-use'
import verifyGroupAllowed from 'utils/verifyGroupAllowed'


type MenuConfig = {
  text: string
  route: string
  icon: string
  groups?: string[]
  extra?: any
}

type LeftBarProps = {
  visible: boolean
  traspasosPendientesRecepcion: number
}

const LeftBar: React.FC<LeftBarProps> = (props) => {
  const appVersion = getAppVersion()
  const permissions = useStoreState(state => state.permissions)
  const [showRecalculoDialog, toggleShowRecalculoDialog] = useToggle(false)
  const allowedRecalculo = permissions.is_superuser || verifyGroupAllowed(['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2'], permissions.groupNames)

  const menuConfig: MenuConfig[] = [
    { text: 'Inicio', route: '/', icon: 'fas fa-home' },
    { text: 'Inventario', route: '/inventory', icon: 'fas fa-clipboard-list' },
    { text: 'Accesorios', route: '/accesorios', icon: 'fas fa-list-alt', groups: ['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2', 'COMERCIAL'] },
    { text: 'Traspasos', route: '/traspasos', icon: 'fas fa-exchange', groups: ['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2'], extra: { items: props.traspasosPendientesRecepcion } },
    { text: 'Clientes', route: '/customers', icon: 'fas fa-user-alt', groups: ['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2', 'COMERCIAL', 'TECNICO'] },
    { text: 'Obras', route: '/buildings', icon: 'fas fa-building', groups: ['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2', 'COMERCIAL'] },
    { text: 'Ofertas', route: '/budgets', icon: 'fas fa-newspaper', groups: ['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2', 'COMERCIAL'] },
    { text: 'Albaranes', route: '/albaranes', icon: 'fas fa-newspaper', groups: ['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2', 'COMERCIAL'] },
    { text: 'Facturación', route: '/facturas', icon: 'fas fa-briefcase', groups: ['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2', 'COMERCIAL'] },
    { text: 'Bancos', route: '/bancos', icon: 'fas fa-piggy-bank', groups: ['DEVS', 'GESTOR 1'] },
    { text: 'Familias', route: '/familias', icon: 'fas fa-list', groups: ['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2'] },
    { text: 'Subfamilias', route: '/subfamilias', icon: 'fas fa-list', groups: ['DEVS', 'GESTOR 1', 'GESTOR 1B', 'GESTOR 2', 'COMERCIAL'] },
    { text: 'Informes', route: '/informes', icon: 'fas fa-file-chart-line', groups: ['DEVS', 'GESTOR 1', 'INFORME_TODOS', 'INFORME_RECLAMAR_COBROS'] },
  ]

  const menuMantenimiento: MenuConfig[] = [
    { text: 'Usuarios', route: '/usuarios', icon: 'fas fa-user-friends', groups: ['DEVS'] },
    { text: 'Festivos', route: '/festivos', icon: 'fas fa-calendar', groups: ['DEVS'] },
    { text: 'Impuestos', route: '/impuestos', icon: 'fas fa-percent', groups: ['DEVS'] },
  ]

  const handleFuerzaRecalculoFacturas = (e) => {
    e.preventDefault()
    toggleShowRecalculoDialog()
  }


  return (
    props.visible ? (
      <div className={`${styles.leftbar} col-md-2 col-sm-2`}>
        <RecalculoFacturacionDialog
          show={showRecalculoDialog}
          onClose={toggleShowRecalculoDialog}
        />
        {
          menuConfig.map((item) => (
            <MenuItem
              {...item}
              key={`${item.route}${item.text}`}
            />
          ))
        }
        <br />
        {
          menuMantenimiento.map((item) => (
            <MenuItem
              {...item}
              key={`${item.route}${item.text}`}
            />
          ))
        }
        <div className='text-center text-light'>{appVersion}</div>
        {
          allowedRecalculo && (
            <button
              type="button"
              onClick={handleFuerzaRecalculoFacturas}
            >
              Forzar recalculo facturas
            </button>
          )
        }
      </div>
    ) : null
  )

}

export default LeftBar