import facturasService from 'services/DataServices/facturasService'
import warehousesService from 'services/DataServices/warehousesService'
import FacturaCalculator from 'data/Factura/FacturaCalculator'


export const updateFactura = async (factura, almacenes) => {
  console.log(factura.ref, 'Actualizando')
  const lineas = await facturasService.lineasFacturasList(factura.id)
  const fc = new FacturaCalculator(factura, lineas)
  const almacen = almacenes.find(a => a.id === factura.almacen.id)

  fc.calculate(almacen.holidays)

  const values = {
    subtotal: fc.data.values.subtotal.toFixed(2),
    descuento: fc.data.values.descuento.toFixed(2),
    impuestos: fc.data.values.impuestos.toFixed(2),
    total: fc.data.values.total.toFixed(2),
    agrupacionImpuestos: JSON.stringify(fc.data.values.agrupacionImpuestos),
    calculada: true
  }


  await facturasService.patch(factura.id, values)
  console.log(factura.ref, 'Actualizada')
}



const fuerzaRecalculoFacturas = async (noCalculadas: boolean = true, ano = 0, mes = 0) => {
  console.log("Obteniendo facturas...")
  const filtro: any = {
    calculada: !noCalculadas,
    fields: 'id,ref,almacen,venta,inicioFactura,finFactura,sabadosHabiles,festivos',
    ordering: 'almacen,ano,numero',
  }

  if (ano) {
    filtro.fechaEmision__year = ano
  }

  if (mes) {
    filtro.fechaEmision__month = mes
  }

  const facturas = await facturasService.list(filtro)
  const almacenes = await warehousesService.list({ ordering: 'id', fields: 'id,holidays' })

  console.log("Procesando facturas...")
  for (let factura of facturas) {
    await updateFactura(factura, almacenes)
  }
  console.log("Facturas recalculadas!!")
}

export default fuerzaRecalculoFacturas