import ModelAPIService from 'services/apiService/ModelAPIService'
import apiService from 'services/apiService'


class FacturasService extends ModelAPIService<FacturaAPI> {
  lineasFacturaURL: string = 'lineasfactura'
  emitirFacturaURL: string = 'emitirFactura'
  cancelarFacturaURL: string = 'cancelarFactura'
  crearFacturasGrupoURL: string = 'creaFacturasGrupo'
  abonarFacturaURL: string = 'abonarFactura'

  constructor() {
    super('facturas')
  }

  lineasFacturasList = async (facturaID: ID): Promise<LineaFacturaAPI[]> => {
    let lineasFactura: LineaFacturaAPI[] = []

    try {
      const res = await apiService.list(this.lineasFacturaURL, { factura: facturaID, ordering: 'orden' })
      lineasFactura = res.data
    } catch (err) {
      console.error(err)
    }

    return lineasFactura
  }

  emitirFactura = async (facturaID: ID): Promise<FacturaAPI> => {
    let factura: FacturaAPI = null

    try {
      const res = await apiService.edit(this.emitirFacturaURL, facturaID, {})
      factura = res.data
    } catch (err) {
      console.error(err)
    }

    return factura
  }

  abonarFactura = async (facturaID: ID): Promise<FacturaAPI> => {
    let factura: FacturaAPI = null

    try {
      const res = await apiService.create(this.abonarFacturaURL, { factura: facturaID })
      factura = res.data
    } catch (err) {
      console.error(err)
    }

    return factura
  }

  cancelarFactura = async (facturaID: ID): Promise<FacturaAPI> => {
    let factura: FacturaAPI = null

    try {
      const res = await apiService.edit(this.cancelarFacturaURL, facturaID, {})
      factura = res.data
    } catch (err) {
      console.error(err)
    }

    return factura
  }

  crearFacturasGrupo = async (data: CreaFacturasGrupo): Promise<any> => {
    let response: any = null
    try {
      const res = await apiService.create(this.crearFacturasGrupoURL, data)
      response = res.data
    } catch (err) {
      console.error(err)
    }

    return response
  }

  sumarizacionFacturas = async (filtro: any): Promise<any> => {
    let response: any = null
    try {
      const res = await apiService.list('sumarizacionFacturas', filtro)
      response = res.data
    } catch (err) {
      console.error(err)
    }

    return response
  }
}

const facturasService = new FacturasService()

export default facturasService