import jwt_decode, { JwtPayload } from 'jwt-decode'
import { useStoreState } from 'store'

import { useInterval } from 'react-use'


const useVerifyJWT = (verificationPeriod = 5000, onFailVerification: () => void) => {
  const { jwt } = useStoreState(state => state)
  // console.log('jwt verify hook')

  useInterval(() => {
    if (jwt) {
      // console.log('Verificando jwt', jwt)
      try {
        const decodedJWT: JwtPayload = jwt_decode(jwt || '')
        // console.log('resultado', decodedJWT)

        const now = (new Date().getTime() + 1) / 1000

        if (!Boolean(decodedJWT) || (decodedJWT.exp && (decodedJWT.exp < now))) {
          console.warn('JWT Caducado')
          if (typeof onFailVerification === 'function') {
            onFailVerification()
          }
        }
      } catch (err) {
        console.warn('No se pudo decodificar el token')
        console.error(err)
        if (typeof onFailVerification === 'function') {
          onFailVerification()
        }
      }
    } else {
      if (typeof onFailVerification === 'function') {
        onFailVerification()
      }
    }
  }, verificationPeriod)
}

export default useVerifyJWT