import DataClass2 from 'data/DataClass2'
import ensureString from 'utils/ensureString'
import parseDateTimeAPI from 'utils/datetime/parseDateTimeAPI'


class LineaFacturaClass extends DataClass2<LineaFacturaAPI, LineaFactura> {
  data: LineaFactura

  constructor(lineaFactura?: LineaFacturaAPI) {
    super()
    this.data = this.normalize(lineaFactura)
  }

  setDescuento = (descuento: string): void => {
    this.data.descuento = descuento
  }

  normalize = (data?: LineaFacturaAPI): LineaFactura => {
    const lineaFactura: LineaFactura = LineaFacturaClass.getDefaultValues()
    this.isNew = !Boolean(data)

    if (!this.isNew) {
      lineaFactura.id = data.id
      lineaFactura.orden = data.orden
      lineaFactura.factura = data.factura
      lineaFactura.albaran = data.albaran
      lineaFactura.texto = data.texto
      lineaFactura.machineDetail = data.maquina?.id && data.maquina
      lineaFactura.maquina = data.maquina?.id || data.maquina
      lineaFactura.accesorio = data.accesorio
      lineaFactura.accesorioExtra = data.accesorioExtra
      lineaFactura.desde = (typeof data.desde == 'string') ? parseDateTimeAPI(data.desde) : data.desde
      lineaFactura.hasta = (typeof data.hasta == 'string') ? parseDateTimeAPI(data.hasta) : data.hasta
      lineaFactura.minDiasFacturables = data.minDiasFacturables
      lineaFactura.cantidad = ensureString(data.cantidad)
      lineaFactura.precio = ensureString(data.precio)
      lineaFactura.impuesto = ensureString(data.impuesto)
      lineaFactura.descuento = ensureString(data.descuento)

      lineaFactura.tipoFacturacion = data.tipoFacturacion
      lineaFactura.sabadosHabiles = data.sabadosHabiles

      lineaFactura.accesorioDetail = data.accesorio && data.accesorioDetail
      lineaFactura.accesorioExtraDetail = data.accesorioExtraDetail
      lineaFactura.numAlbaran = data.numAlbaran
      lineaFactura.tipoLinea = data.tipoLinea
    }

    return lineaFactura
  }


  static getDefaultValues = (sabadosHabiles = false): LineaFactura => {
    const lineaFactura: LineaFactura = {
      orden: 0,
      factura: null,
      albaran: null,
      texto: '',
      maquina: null,
      accesorio: null,
      accesorioExtra: null,
      desde: null,
      hasta: null,
      minDiasFacturables: 1,
      cantidad: '1',
      precio: '0.00',
      impuesto: '7.00',
      descuento: '0',
      tipoFacturacion: '',
      // ## Resto de campos
      isNew: true,
      isEdited: false,
      isDeleted: false,
      machineDetail: null,
      accesorioDetail: null,
      accesorioExtraDetail: null,
      sabadosHabiles: Boolean(sabadosHabiles),

      festivos: [],
      diasAlquiler: '0',
      values: null,
      numAlbaran: '',
      tipoLinea: ''
    }

    return lineaFactura
  }
}


export default LineaFacturaClass