//import formatISO from 'date-fns/formatISO'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'


const formatDateTimeAPI = (date: Date | null): string | null => {
  // console.log('formatDateTimeAPI', date)
  if (isValid(date)) {
    // const result = date ? formatISO(date) : null
    const result = date ? `${format(date, 'yyyy-MM-dd')}T${format(date, 'HH:mm:ss')}` : null

    // 2022-02-03T00:00:00Z
    // console.log('formatDateTimeAPI', result)
    return result
  }
  // console.log('formatDateTimeAPI', null)
  return null
}


export default formatDateTimeAPI