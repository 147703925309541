import parseISO from 'date-fns/parseISO'

const parseDateAPI = (date: string | null): Date | null => {
  // Del API las fechas vienen codificadas como YYYY-MM-DD
  const result = date ? parseISO(date) : null

  return result
}


export default parseDateAPI