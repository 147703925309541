import isObject from './isObject'


const objectIDOrNull = (value: any): (ID | null) => {
  const hasID = isObject(value) && Boolean(value?.id)
  const result = hasID ? value.id : null

  return result
}


export default objectIDOrNull