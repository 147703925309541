import styles from './styles.module.sass'


type AwaitingOverlayProps = {
  awaiting: boolean
}


const AwaitingOverlay: React.FC<AwaitingOverlayProps> = (props) => {
  const { awaiting } = props
  const awaitingStyles = ['primary', 'success', 'danger', 'warning', 'info', 'light']
  // const [style, setStyle] = useState<string>(awaitingStyles[0])

  /*
  useEffect(() => {
    const timer = setInterval(() => {
      const index = randomIntFromInterval(0, awaitingStyles.length - 1)
      setStyle(awaitingStyles[index])
    }, 750)

    return () => {
      clearInterval(timer)
    }
  }, [awaitingStyles])
  */

  return (
    Boolean(awaiting) ? (
      <div className={styles.awaitingOverlay}>
        <div className="spinner-box">
          <div className={`spinner-border text-${awaitingStyles[0]}`} style={{ width: '6rem', height: '6rem', borderWidth: '1rem' }} role="status" />
        </div>
      </div>
    ) : null
  )
}

export default AwaitingOverlay

