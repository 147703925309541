export enum MaquinaStatus {
  DISPONIBLE = 0,
  ALQUILADA = 10,
  TALLER = 100,
  TALLER_REVISION = 110,
  TALLER_REPARACION = 115,
  TRANSITO = 200,
  VENDIDA = 500,
  NO_REPARABLE = 600
}

export enum TipoFacturacionMaquina {
  Natural = 'N', // Por dia natural
  Laboral = 'L'  // Por dia laboral
}

export enum MAQUINA_ESTADO {
  DISPONIBLE = 0,
  ALQUILADA = 10,
  EN_TALLER = 100,
  EN_REVISION = 110,
  EN_REPARACION = 115,
  EN_TRANSITO = 200,
  VENDIDA = 500,
  NO_REPARABLE = 600,
}

export enum MAQUINA_TIPO_FACTURACION {
  DIA_NATURAL = 'N',
  DIA_LABORAL = 'L'
}