import AxiosError from 'axios-error'


const apiConsoleError = (error: any) => {
  const axiosError = new AxiosError(error)
  console.log(axiosError)
  console.log(axiosError.stack) // error stack trace
  console.log(axiosError.config) // axios request config
  console.log(axiosError.request) // HTTP request
  console.log(axiosError.response)
}

export default apiConsoleError