import { useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useToggle } from 'react-use'
import { useStoreState } from 'store'

import TopBar from './TopBar'
import LeftBar from './LeftBar'
import AwaitingOverlay from 'BaseLayout/AwaitingOverlay'
import ErrorPage from 'components/ErrorPage'

import styles from './styles.module.sass'


const errorHandler = (error, { componentStack }) => {
  // Aquí se debería hacer algo con el error, como enviarlo por telegram

}

type LayoutProps = {}

const Layout: React.FC<LayoutProps> = (props) => {
  const { children } = props
  const [leftMenuVisible, toggleLeftMenuVisible] = useToggle(true)
  const { awaiting, printing, traspasosPendientesRecepcion } = useStoreState(store => store)

  const toggleLeftMenu = useCallback(
    () => { toggleLeftMenuVisible() },
    [toggleLeftMenuVisible]
  )

  return (
    <div className={styles.appcontainer}>
      <AwaitingOverlay awaiting={awaiting || printing} />
      <TopBar onCollapse={toggleLeftMenu} />
      <div className={`row ${styles.row} ${styles.appcontent}`}>
        <LeftBar visible={leftMenuVisible} traspasosPendientesRecepcion={traspasosPendientesRecepcion} />
        <div className="col d-flex flex-column py-1 bg-white" style={{ minHeight: '100%', height: '100%', overflowY: 'scroll' }}>
          <ErrorBoundary FallbackComponent={ErrorPage} onError={errorHandler}>
            {children}
          </ErrorBoundary>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

Layout.whyDidYouRender = false

export default Layout