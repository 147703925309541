import logdown from 'logdown'


const getLogger = (moduleName: string): any => {
  const logger = logdown(moduleName)
  // logger.state.isEnabled = Boolean(window.DEBUG__ENABLED)

  return logger
}

export default getLogger