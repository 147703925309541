

if (window.Handlebars) {
  window.Handlebars.registerHelper(
    'notEmpty',
    function (value, options) {
      if (value != "") return options.fn(this)
    })
} else {
  console.error('No se encontró Handlebars')
}