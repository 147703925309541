import Big from 'big.js'

import FacturaClass from './FacturaClass'
import LineaFacturaCalculator from 'data/LineaFactura/LineaFacturaCalulator'

Big.DP = 4
Big.RM = 1




class FacturaCalculator extends FacturaClass {
  lineas: LineaFacturaCalculator[] = []

  constructor(factura: FacturaAPI, lineas: LineaFactura[] | LineaFacturaAPI[]) {
    super(factura)
    this.lineas = lineas.map(linea => (new LineaFacturaCalculator(linea)))
  }

  calculate = (festivos: (string | Date)[]): FacturaCalculationsResult => {
    const params = { festivos, factura: this.data.apiData }

    if (this.data.festivos.length) {
      params.festivos = this.data.festivos
    }

    const linesCalcs = this.lineas.map(linea => linea.calculate(params))

    let subtotal = new Big('0.0')
    let descuento = new Big('0.0')
    let impuestos = new Big('0.0')
    let total = new Big('0.0')

    const impuestosUsados = Array.from(new Set(this.lineas.map(linea => linea.data.values.taxRate))).sort((a, b) => (parseFloat(a) - parseFloat(b)))

    const agrupacionImpuestos = []

    impuestosUsados.forEach(tipo => {
      const lineas = this.lineas.filter(linea => (tipo === linea.data.values.taxRate))

      const baseImponible: Big = lineas.reduce((acc: Big, value: LineaFacturaCalculator) => {
        const subtotal = new Big(value.data.values.subtotal)
        const descuento = new Big(value.data.values.discount)

        const result = acc.plus(subtotal).minus(descuento)

        return result
      }, new Big(0.0))

      //const importeImpuesto = lineas.reduce((acc: Big, value: LineaFacturaCalculator) => {
      //  const tax = new Big(value.data.values.tax)

      //  const result = acc.plus(tax)

      //  return result
      //}, new Big(0.0))


      const importeImpuesto = baseImponible.times(tipo).div(100.0)

      const values = {
        tipo,
        baseImponible: baseImponible.toFixed(2),
        importeImpuesto: importeImpuesto.toFixed(2)
      }

      agrupacionImpuestos.push(values)
    })

    linesCalcs.forEach(lineCalc => {
      descuento = descuento.plus(lineCalc.discount)
      subtotal = subtotal.plus(lineCalc.subtotal)
      impuestos = impuestos.plus(lineCalc.tax)
      total = total.plus(lineCalc.total)
    })

    this.data.values = {
      agrupacionImpuestos,
      descuento,
      subtotal,
      impuestos,
      total,
    }

    this.data.subtotal = this.data.values.descuento.toFixed(2)
    this.data.descuento = this.data.values.subtotal.toFixed(2)
    this.data.impuestos = this.data.values.impuestos.toFixed(2)
    this.data.total = this.data.values.total.toFixed(2)
    this.data.agrupacionImpuestos = this.data.values.agrupacionImpuestos

    return this.data.values
  }
}


export default FacturaCalculator