import { createTypedHooks } from 'easy-peasy'
import { AppState } from './types'
import store from './model'


const typedHooks = createTypedHooks<AppState>()


export const useStoreActions = typedHooks.useStoreActions
export const useStoreDispatch = typedHooks.useStoreDispatch
export const useStoreState = typedHooks.useStoreState


export default store